<template>
  <div class="nav">
    <div class="logo">
      <img v-if="logoPath!=''" :src="logoPath" @click="toIndex">
      <el-icon v-else class="el-icon-loading"></el-icon>
    </div>
    <div class="text text-right">
      <span @click="toIndex">首页</span>
      <span v-for="(item,index) in channelList" @click="toChannel(item.id,item.name,item.id,item.name,0)" :key="index" @mouseenter="showChildBox(item)" @mouseleave="hideChildBox">{{ item.name }}</span>
      <span @mouseenter="showHideLink(true)" @mouseleave="showHideLink(false)">官方商城</span>
    </div>
  </div>
<!--子栏目显示-->
  <div class="child-box" v-show="showChild" @mouseenter="showChildBox" @mouseleave="hideChildBox">
    <div class="child-box-content" v-for="c in childChannelList" @click="toChannel(curParentId,curParentName,c.id,c.name,1)">
      <img :src="serverUrl+c.icon" v-show="c.icon"/>
      <span>{{c.name}}</span>
    </div>
  </div>
<!--官方商城超链接-->
  <div class="child-box" v-show="showLink" @mouseenter="showHideLink(true)" @mouseleave="showHideLink(false)">
    <div class="child-box-content" v-for="c in linkList" @click="openUrl(c.url,1,c.id)">
      <img :src="serverUrl+c.icon" v-show="c.icon"/>
      <span>{{c.name}}</span>
    </div>
  </div>

  <!-- 公众号二维码 -->
  <div class="qrcode">
    <div class="phone"><img src="../../assets/images/front/phone.png">{{ telephone }}</div>
    <!--   需要判断显示不同公众号二维码   -->
    <img v-if="qrcodePath!=''" :src="qrcodePath">
    <el-icon v-else class="el-icon-loading"></el-icon>
    <div style="color: #FFFFFF">扫码关注公众号</div>
    <div class="feedback" @click="dialogVisible=true">
      <img src="../../assets/images/front/feedback.png">
      <span>在线留言</span>
    </div>
  </div>
  <!--留言弹窗-->
  <el-dialog
      v-model="dialogVisible"
      title="在线留言"
      width="800px"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="{
          linkman:[{required:true,message:'请输入姓名',trigger:'blur'}],
          product:[{required:true,message:'请输入产品',trigger:'blur'}],
          content:[{required:true,message:'请输入留言',trigger:'blur'}]
        }"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="linkman" class="label-color">
        <el-input v-model="ruleForm.linkman" placeholder="姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="产品" prop="product" class="label-color">
        <el-input v-model="ruleForm.product" placeholder="产品" clearable></el-input>
      </el-form-item>
      <el-form-item label="留言" prop="content" class="label-color">
        <el-input v-model="ruleForm.content" :autosize="{ minRows: 4 }"
                  type="textarea" placeholder="留言" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveFeedback">提交</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>

import feedbackApi from "@/api/info/feedback";
import channelApi from "@/api/info/channel";
import variableApi from "@/api/system/variable";
import friendlinkApi from "@/api/info/friendlink";
import config from "@/api/config";
import rotationApi from "../../api/info/rotation";

export default {
  name: "head",
  props: {
    serachKey: String
  },
  data() {
    return {
      serverUrl:config.server,
      qrcodePath: '',
      logoPath: '',
      level: 1,
      dialogVisible: false,
      ruleForm: {
        linkman: '',
        product: '',
        content: '',
      },
      channelList: [],
      childChannelList: [
        /*{id:3,icon:require('@/assets/images/front/product.jpg'),name:'集成水槽'},
        {id:3,icon:require('@/assets/images/front/product.jpg'),name:'集成水槽'},
        {id:3,icon:'',name:'集成水槽'},*/
      ],
      telephone: '',
      showChild: false,// 导航栏子级
      showLink: false,// 商城链接
      curParentId:'',// 当前导航栏父级id
      curParentName:'',// 当前导航栏父级名称
      linkList:[],// 官方商城超链接
      childChannelMap:{}// 导航栏子级map
    }
  },
  mounted() {
    this.queryHeadChannel();
    //获取系统变量-电话
    variableApi.queryVarNumVariable({varNum: "telephone"}).then(res => {
      this.telephone = res.data.data.telephone;
    })
    //获取系统变量-logo
    variableApi.queryVarNumVariable({varNum: "logo"}).then(res => {
      if (res.data.code == 1) {
        this.logoPath = config.server + res.data.data.logo;
      }
    })
    //获取系统变量-公众号二维码
    variableApi.queryVarNumVariable({varNum: "qrcode"}).then(res => {
      if (res.data.code == 1) {
        this.qrcodePath = config.server + res.data.data.qrcode;
      }
    })

    // 超链接
    this.queryLink('官方商城');
  },
  methods: {
    // 获取友情链接
    queryLink(groupName) {
      friendlinkApi.queryLink({groupName:groupName}).then((res) => {
        this.linkList = res.data.data;
      })
    },
    showChildBox(parentChannel){
      if(parentChannel==undefined||parentChannel.id==undefined){
        this.showChild=true;
        return;
      }
      // 根据parentid获取子级栏目列表，当前id不等于时才查询
      this.childChannelList = this.childChannelMap[parentChannel.id];
      // 当有子级时才显示
      if(this.childChannelList.length>0){
        this.showChild=true;
      }
      this.curParentId=parentChannel.id;
      this.curParentName=parentChannel.name;
    },
    hideChildBox(){
      this.showChild=false;
    },
    showHideLink(flag){
      this.showLink=flag;
    },
    toIndex() {
      this.$router.push({
        path: '/',
      })
    },
    //查询顶级栏目列表
    queryHeadChannel() {
      channelApi.queryHeadChannel().then(res => {
        this.channelList = res.data.data;
        // 循环获取自己栏目
        for(let i=0;i<this.channelList.length;i++){
          let cid=this.channelList[i].id;
          channelApi.queryChildChannel(cid).then((response) => {
            if(response.data.code==1){
              let childList = response.data.data;
              // 当有子级时放入map
              if(childList.length>0){
                this.childChannelMap[cid]=childList;
              }else{
                this.childChannelMap[cid]=[];
              }
            }
          });
        }
      })
    },
    toChannel(pid,pname,id, name,level) {
      let channelId=id;
      let channelName=name;
      // 如果点击的是顶部栏目，如果有子集，默认第一个
      if(level==0 && this.childChannelMap[id].length>0){
        channelId =this.childChannelMap[id][0].id;
        channelName = this.childChannelMap[id][0].name;
      }
      this.$router.push({
        path: '/channel',
        query: {
          parentChannelId:pid,
          parentChannelName:pname,
          channelId: channelId,
          channelName: channelName
        }
      });
      //返回顶部
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    // 保存留言信息
    saveFeedback() {
      this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              // 调用方法保存
              feedbackApi.addFeedback(this.ruleForm).then((response) => {
                if (response.data.code == 1) {
                  this.$notify({
                    title: "提交成功，感谢您的留言！",
                    type: "success",
                    offset: 40,
                    duration: 2000,
                  });
                  // 关闭窗口
                  this.dialogVisible = false;
                } else {
                  this.$notify.info({
                    title: "保存失败：" + response.data.msg,
                    type: "warning",
                    offset: 40,
                    duration: 2000,
                  });

                }
              });

            }
          }
      );
    },

    // 更多跳转
    viewMore(channelId, channelName) {
      this.$router.push({
        path: '/channel',
        query: {
          channelId: channelId,
          channelName: channelName
        }
      });
    },
    // 打开友情链接或轮播图跳转地址
    openUrl(url,type,id) {
      // 点击轮播图
      if(type==0){
        // 记录点击量
        rotationApi.click(id);
      }else{// 点击友情链接
        // 记录点击量
        friendlinkApi.click(id);
      }
      if(url!=null&&url!=''){
        window.open(url);
      }
    },

  },
}
</script>

<style scoped>
.logo {
  display: inline-block;
  text-align: center;
  float: left;
  height: 100%;
  vertical-align: middle;
}

.logo img {
  cursor: pointer;
  height: 35px;
  vertical-align: middle;
}

.text {
  display: inline-block;
  /*width: 500px;*/
  float: right;
}

.text span {
  display: inline-block;
  height: 78px;
  cursor: pointer;
  padding: 0px 40px;
  margin: 1px 0;
}

.text span:hover {
  box-sizing: border-box; /*内边框*/
  border-bottom: white solid 2px;
  font-weight: bold;
}

.p-left {
  padding-left: 90px !important;
}

.p-right {
  padding-right: 90px !important;
}


.text-right {
  text-align: right;
}

/*右侧联系方式*/
.qrcode {
  /*width: 200px;*/
  z-index: 99;
  position: fixed;
  top: 400px;
  right: 10px;
  display: block;
  text-align: center;
}

.qrcode img {
  height: 150px;
}

.phone {
  margin: 5px 0;
  color: #409eff;
  height: 25px;
  line-height: 25px;
}

.phone img {
  padding-right: 5px;
  height: 25px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.feedback {
  margin-top: 10px;
  background-color: #409eff;
  color: white;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  cursor: pointer;
}

.feedback span {
  height: 35px;
  line-height: 35px;
}

.feedback img {
  padding-right: 5px;
  height: 25px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.child-box{
  text-align: center;
  background-color: #000000;
  color: white;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 100;
  /*padding: 20px;*/
}
.child-box .child-box-content{
  display: inline-block;
  width: 100px;
  text-align: center;
  padding:20px;
  cursor: pointer;
}
.child-box-content img{
  width:40px;
  /*height:40px;*/
}
.child-box-content span{
  display: block;
  font-size: 16px;
  line-height: 35px;
}
</style>