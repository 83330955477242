<template>
  <div class="footer">
    <p>Copyright © 2023 By {{ company }} 版权所有 All Rights Reserved. </p>
    <p>备案：<a href="https://beian.miit.gov.cn" target="_blank">{{siteNum}}</a> 技术支持：广西优码信息技术有限公司</p>
  </div>
  <!--回到顶部-->
  <el-backtop/>
</template>

<script>
import {ref, onMounted} from 'vue'
import variableApi from "@/api/system/variable";

export default {
  name: "footer",
  setup() {
    /**网站备案号*/
    const siteNum = ref('');
    /**单位名称*/
    const company = ref('');
    /**
     * @创建人：YangLei
     * @创建时间：2022-7-28 18:18
     * @描述：获取网站备案号
     **/
    const getSiteNum = () => {
      variableApi.queryVarNumVariable({varNum: 'siteNum'}).then(res => {
        siteNum.value = res.data.data.siteNum;
      })
      variableApi.queryVarNumVariable({varNum: 'company'}).then(res => {
        company.value = res.data.data.company;
      })
    }
    onMounted(() => {
      getSiteNum();
    })
    return {
      siteNum,
      company,
    }
  }
}

</script>

<style scoped>
.footer {
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.footer p a {
  text-decoration: none;
  color: #fff;
}
</style>